@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

@font-face {
    font-family: "Montserrat";
    src: url("../Fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Helvetica";
    src: url("../Fonts/Helvetica.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("../Fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "IntAvantGardeer";
    src: url("../Fonts/AvantGarde.otf");
}

@font-face {
    font-family: "ArchitectsDaughter";
    src: url("../Fonts/ArchitectsDaughter.ttf");
}


@tailwind base;
@tailwind components;
@tailwind utilities;