/*
Highlight Text
*/

.highlight {
    position: relative;
}

.logo {
    width: 200px;
}

.about-icon {
    width: 1.3rem;
    height: 1.3rem;
}

.icon {
    font-size: 20px;
}

.highlight {
    background-position: 20% 25px;
    background-repeat: no-repeat;
    background-size: 87% 1em;
    background-image: linear-gradient(to right, #fece8e 100%, #c7202000);
}

.title {
    background: linear-gradient(180deg, rgba(74, 108, 247, 0.4) 0%, rgba(74, 108, 247, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: .2;
    /* position: absolute; */
    left: 50%;
    top: 0;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    font-size: 40px;
    font-weight: 800;
    line-height: 1 !important
}

@media(min-width:540px) {
    .title {
        font-size: 60px
    }
}

@media(min-width:992px) {
    .title {
        font-size: 95px
    }
}

.dark .title {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)
}

.img-w {
    width: 100%;
    height: auto;
}


/* Nav */

header {
    background: var(--primary-dark);
    position: relative;
    box-shadow: 0 4px 17px rgb(255 255 255 / 10%);
    position: sticky;
    top: 0;
    z-index: 1;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.logo {
    font-size: 1.5rem;
    font-weight: 800;
    opacity: 0;
    animation: leftSideAni 1s ease forwards;
}

.logo a {
    color: #fff;
    text-decoration: none;
}

ul {
    display: flex;
    gap: .6rem;
    list-style-type: none;
    background-color: transparent;
    overflow: hidden;
}

li a {
    display: inline-block;
    /* padding: .3rem 1rem; */
    color: rgb(55 65 81);
    text-decoration: none;
    font-size: 1.05rem;
    font-weight: 600;
    letter-spacing: .7px;
    border-radius: 36px;
    line-height: 2;
    /* transition: .3s;
    opacity: 0; */
    /* animation: navani .3s ease forwards;
    animation-delay: calc(.15s * var(--navAni)); */
}

li a.active,
li a:hover {
    border-bottom: 2px solid #9ca3af;
    border-radius: 0px;
    color: #000;
}

#click {
    display: none;
}

.menu {
    display: none;
}

.mode i {
    font-size: 1.4rem;
    cursor: pointer;
    color: var(--primary-color);
    opacity: 0;
    animation: rightSideAni 1.5s ease forwards;
}


/* Nav End */


/* Section 1 */

.main button {
    background-color: transparent;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 0.8rem 1rem;
    font-size: 1.1rem;
    border-radius: 7px;
    margin-top: 2rem;
    cursor: pointer;
}

.images {
    margin-left: auto;
    width: 300px;
}

.images img {
    border-radius: 50%;
    box-shadow: 0 0 10px #D1D9E6;
}


/* Section 1 End */


/* Roundin sec */

.main {
    position: relative;
}

.big-circle {
    height: 100%;
    width: 100%;
    position: relative;
    border: 3px solid #bbbbbb;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    animation: Rotate 20s linear infinite;
    -webkit-animation: Rotate 20s linear infinite;
}

.icon-block {
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: #67d0dc;
    box-shadow: 0 0 10px #67d0dc;
}

.icon-block img {
    margin: 0px auto;
    width: 60%;
    animation: Rotate-reverse 20s linear infinite;
    -webkit-animation: Rotate-reverse 20s linear infinite;
}

.icon-block:first-child {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.icon-block:nth-child(2) {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
}

.icon-block:nth-child(3) {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, 50%);
}

.icon-block:nth-child(4) {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.rounding-sec {
    position: relative;
    height: 400px;
    width: 400px;
}

.images {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}


/* keyframe animation */

@keyframes Rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes Rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes Rotate-reverse {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes Rotate-reverse {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
    }
}


/* Animation */

@keyframes leftSideAni {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes navani {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes topSideAni {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 990px) {
    .main {
        grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    }

    .rounding-sec {
        margin: auto;
    }
}

@media screen and (max-width:600px) {
    .main {
        grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));
        margin-top: 2rem;
        overflow-x: hidden;
    }

    .icon-block {
        width: 50px;
        height: 50px;
    }

    .rounding-sec {
        height: 300px;
        width: 300px;
    }

    .images {
        width: 200px;
    }
}